<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-input v-model.trim="formData.code" placeholder="请输入借出单号" style="width: 350px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>
      <OrderInfo :order="order"/>
      <el-form :model="formData" label-width="250px">
        <el-form-item label="备件编码：">
          <el-select v-model="formData.materialCode" @change="materialCodeChange" placeholder="请选择备件编码" class="form-item" filterable>
            <el-option v-for="item in itemList" :key="item.id" :label="item.materialCode+'【'+item.materialName+'】'" :value="item.materialCode"></el-option>
          </el-select>
          <div style="display: inline-block;padding-left: 10px" v-show="materialCountTip">
            检验要求/已检验：
            外观：<span class="line-tip" v-text="countTip.appearanceTotal+' - '+countTip.appearanceCnt"></span>
            电性能：<span class="line-tip" v-text="countTip.electricTotal+' - '+countTip.electricCnt"></span>
          </div>
        </el-form-item>
        <el-form-item label="本次自动检验的类型：">
          <el-select v-model="formData.sortTestType" placeholder="请选择检验类型" class="form-item">
            <el-option label="外观" value="appearance"></el-option>
            <el-option label="电性能" value="electric"></el-option>
            <el-option label="金钥匙" value="goldenKey"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="本次自动检验数量：">
          <el-input v-model="formData.testNum" ref="testNum" type="number" max="10000" min="0" maxlength="5" placeholder="请输入检验数量" class="form-item"></el-input>
        </el-form-item>
        <el-form-item label="检验人员账号：">
          <el-select v-model="formData.userIds" multiple placeholder="请输入检验人员账号" class="form-item" filterable>
            <el-option v-for="item in userList" :key="item.id" :label="item.fullName+'【'+item.userName+'】'" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="不良品：">
          <el-switch v-model="formData.isNg" @change="loadBusTrouble"></el-switch>
        </el-form-item>
        <div v-if="needTest1">
          <el-row>
            <el-col :span="12">
              <el-form-item label="外观检验结果：">
                <el-radio-group v-model="formData.test1Result">
                  <el-radio :label="true">正常</el-radio>
                  <el-radio :label="false">不良</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
          <el-row v-show="formData.test1Result===false">
            <el-col :span="12">
              <el-form-item label="外观不良代码：">
                <el-select v-model="formData.test1TroubleCode" filterable placeholder="请选择外观不良代码" class="form-item">
                  <el-option
                      v-for="item in troubleList"
                      :key="item.code"
                      :label="item.code+' '+item.name"
                      :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="外观是否可修复：">
                <el-radio-group v-model="formData.test1Repairable">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="formData.test1Result===false">
            <el-col :span="12">
              <el-form-item label="外观不良原因：">
                <el-input v-model="formData.test1TroubleReason" type="textarea" :rows="2" class="form-item"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
        </div>
        <div v-if="needTest2">
          <el-row>
            <el-col :span="12">
              <el-form-item label="电性能检验结果：">
                <el-radio-group v-model="formData.test2Result">
                  <el-radio :label="true">正常</el-radio>
                  <el-radio :label="false">不良</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12"></el-col>
          </el-row>
          <el-row v-show="formData.test2Result===false">
            <el-col :span="12">
              <el-form-item label="电性能不良代码：">
                <el-select v-model="formData.test2TroubleCode" filterable placeholder="请选择电性能不良代码" class="form-item">
                  <el-option
                      v-for="item in troubleList"
                      :key="item.code"
                      :label="item.code+' '+item.name"
                      :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电性能不良原因：">
                <el-input v-model="formData.test2TroubleReason" type="textarea" :rows="2" class="form-item"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row v-if="formData.isNg===true">
          <el-col :span="12">
            <el-form-item label="检验结果：">
              <el-select v-model="formData.testResult" class="form-item" filterable>
                <el-option
                    v-for="item in testResultList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验结果描述：">
              <el-input v-model="formData.testResultRemark" type="textarea" :rows="2" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="formData.isNg===true">
          <el-col :span="12">
            <el-form-item label="处置意见：">
              <el-select v-model="formData.disposeOpinion" class="form-item" filterable>
                <el-option
                    v-for="item in disposeOpinionList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目标库区：">
              <el-select v-model="formData.storeType" class="form-item" filterable>
                <el-option
                    v-for="item in storeTypeList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button type="primary" @click="submit" :loading="commitLoading">提交</el-button>
          <MessageComponent ref="commitMessage"/>
        </el-form-item>
      </el-form>
    </el-card>

  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";
import OrderInfo from "@/views/sortMaterial/OrderInfo";

export default {
  name: "AutoTest",
  components: {MessageComponent, OrderInfo},
  data() {
    return {
      formData: {
        orderId: '',
        code: '',
        materialCode: '',
        sortTestType: '',
        testNum: '',
        userIds: [],
        isNg: false,
        test1Result: false,
        test1TroubleCode: '',
        test1Repairable: false,
        test1TroubleReason: '',
        test2Result: false,
        test2TroubleCode: '',
        test2TroubleReason: '',
        testResult: '',
        testResultRemark: '',
        disposeOpinion: '',
        storeType: ''
      },
      loading: false,
      commitLoading: false,
      order: {},
      itemList: [],
      userList: [],
      troubleList: [],
      materialCountTip: false,
      countTip: {
        appearanceCnt: 0,
        electricCnt: 0,
        appearanceTotal: 0,
        electricTotal: 0
      }
    };
  },
  computed: {
    needTest1() {
      return this.formData.isNg;
    },
    needTest2() {
      return this.formData.isNg && (this.formData.sortTestType == 'electric' || this.formData.sortTestType == 'goldenKey');
    },
    disposeOpinionList() {
      return this.$store.getters.getDictList('disposeOpinion') || [];
    },
    testResultList() {
      return this.$store.getters.getDictList('testResult') || [];
    },
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    },
  },
  created() {
    this.$axios.get('user/queryTestUser').then(response => {
      if (response.data.code !== 0) {
        return this.$message.error('获取检验人员数据失败');
      }
      this.userList = response.data.data || [];
    })
  },
  methods: {
    resetValue() {
      this.formData.userIds = [];
      this.formData.testNum = '';
      this.formData.sortTestType = ''

      this.formData.isNg = false
      this.formData.test1Result = false
      this.formData.test1TroubleCode = ''
      this.formData.test1Repairable = false
      this.formData.test1TroubleReason = ''
      this.formData.test2Result = false
      this.formData.test2TroubleCode = ''
      this.formData.test2TroubleReason = ''
      this.formData.testResult = ''
      this.formData.testResultRemark = ''
      this.formData.disposeOpinion = ''
      this.formData.storeType = ''
      this.troubleList = [];

      this.materialCountTip = false
      this.countTip = {
        appearanceCnt: 0,
        electricCnt: 0,
        appearanceTotal: 0,
        electricTotal: 0,
        itemList: [],
      }
    },
    materialCodeChange() {
      this.$axios.post('/busItem/queryTestNum', {
        orderId: this.order.id,
        materialCode: this.formData.materialCode
      }).then(response => {
        if (response.data.code !== 0) {
          this.$message.error('获取备件检验数量错误');
          return;
        }
        let data = response.data.data;
        this.countTip.appearanceCnt = data.appearanceCnt;
        this.countTip.electricCnt = data.electricCnt;
        this.countTip.appearanceTotal = data.appearanceTotal;
        this.countTip.electricTotal = data.electricTotal;
        this.materialCountTip = true;
      })
      this.loadBusTrouble();
    },
    loadBusTrouble() {
      if (!this.formData.isNg || !this.formData.materialCode) {
        return;
      }
      this.$axios.post('busTrouble/getBusTrouble', {
        orderId: this.order.id,
        materialCode: this.formData.materialCode
      }).then(response => {
        if (response.data.code !== 0) {
          return this.$message.error(response.data.message);
        }
        this.troubleList = response.data.data || [];
      })
    },
    async search() {
      this.$refs.searchMessage.clear()
      this.$refs.commitMessage.clear()
      this.order = {};
      this.itemList = []
      this.resetValue()
      this.formData.materialCode = '';

      this.loading = true;
      const {data: res} = await this.$axios.get('/lendOrder/queryOrderForAutoTest', {
        params: {
          code: this.formData.code
        }
      })
      this.loading = false;
      if (res.code !== 0) {
        return this.$refs.searchMessage.showError(res.message);
      }
      this.order = res.data.order || {};
      this.itemList = res.data.itemList || [];

    },
    async submit() {
      this.$refs.commitMessage.clear();
      if (!this.order.id) {
        return this.$refs.commitMessage.showError('请先输入借出订单号查询借出订单信息');
      } else {
        this.formData.orderId = this.order.id
      }
      if (!this.formData.materialCode) {
        return this.$refs.commitMessage.showError('请选择备件编码');
      }
      if (!this.formData.sortTestType) {
        return this.$refs.commitMessage.showError('请选择检验类型');
      }
      if (!this.formData.testNum) {
        return this.$refs.commitMessage.showError('请输入本次要检验的数量');
      } else if (this.formData.testNum < 1) {
        return this.$refs.commitMessage.showError('本次要检验的数量不正确');
      }
      if (!this.formData.userIds || this.formData.userIds.length == 0) {
        return this.$refs.commitMessage.showError('请选择检验人员账号');
      }
      if (this.formData.sortTestType == 'sortTestType') {
        if (this.formData.testNum > this.countTip.appearanceTotal - this.countTip.appearanceCnt) {
          return this.$refs.commitMessage.showError('检验数量大于外观未检数量');
        }
      } else if (this.formData.sortTestType == 'electric' || this.formData.sortTestType == 'goldenKey') {
        if (this.formData.testNum > this.countTip.electricTotal - this.countTip.electricCnt) {
          return this.$refs.commitMessage.showError('检验数量大于电性能未检数量');
        }
      }

      if (this.formData.isNg) {
        if (this.formData.sortTestType == 'appearance') {
          if (this.formData.test1Result === true) {
            return this.$refs.commitMessage.showError('检验类型为外观时，不良品检验外观结果不能为合格');
          }

          if (!this.formData.test1TroubleCode) {
            return this.$refs.commitMessage.showError('外观不良代码不能为空');
          }
        } else {
          if (this.formData.test1Result === true && this.formData.test2Result === true) {
            return this.$refs.commitMessage.showError('不良品检验外观结果和电性能结果不能全为合格');
          }

          if (this.formData.test1Result !== true && !this.formData.test1TroubleCode) {
            return this.$refs.commitMessage.showError('请选择外观不良代码');
          }
          if (this.formData.test2Result !== true && !this.formData.test2TroubleCode) {
            return this.$refs.commitMessage.showError('请选择电性能不良代码');
          }

        }

        if (!this.formData.testResult) {
          return this.$refs.commitMessage.showError('检验结果不能为空');
        }
        if (!this.formData.disposeOpinion) {
          return this.$refs.commitMessage.showError('处置意见不能为空');
        }

        if (this.formData.testResult == '1') {
          return this.$refs.commitMessage.showError('不良品的检验结果不能为合格');
        }
        if (this.formData.disposeOpinion == '20') {
          return this.$refs.commitMessage.showError('不良品的处置意见不能为合格');
        }

        if (!this.formData.storeType) {
          return this.$refs.commitMessage.showError('目标库区不能为空')
        }

      }

      this.commitLoading = true;
      const {data: res} = await this.$axios.post('busItem/saveAutoTestResult', this.formData)
      this.commitLoading = false;
      if (res.code !== 0) {
        return this.$refs.commitMessage.showError(res.message);
      }
      this.$refs.commitMessage.showSuccess('自动检验成功，检验条数：' + res.data);
      this.resetValue();
      this.materialCodeChange()
    }
  }
}
</script>

<style scoped>
.box-card {
  padding-bottom: 100px;
}

.line-tip {
  color: red;
  font-size: 18px;
  padding-right: 10px;
}

.form-item {
  width: 400px;
}

.tip {
  color: goldenrod;
}
</style>